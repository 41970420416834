import React from "react";
import "./ClientQueryForm.css";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import formLogo from "./../../assets/formIcon.png";
import SettingValues from "../../Settings";
// import InputArea from "../../Components/InputArea/InputArea";

import logo from "./../../assets/logo_qs.png";
import ClientForm from "../../Components/ClientForm/ClientForm";

export default function FormArea() {
  return (
    // <div className="main-working-div">
    <Container fluid="sm">
      <Row>
        <Col lg={8} md={8}>
          <div>
            <h2 className="formName">
              <img
                className="formLogo"
                src={formLogo}
                alt="logo"
                width={70}
                height={70}
              />
              {SettingValues.formName}
            </h2>
            <div className="decurationHR"></div>
          </div>
        </Col>
        <Col lg={4} md={4}>
          <div className="logo-container">
            <img
              className="logo-img"
              src={logo}
              alt="logo"
              width={60}
              height={60}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ClientForm />
        </Col>
      </Row>
    </Container>
    // </div>

    // <div className = "main--div">
    //     <div className = "main-working-div" >

    //         <div className="logoArea--Dix">
    //             <div>
    //                 <img  className="formLogo" src={formLogo} alt="logo" />
    //                 <h1 className="formName">{SettingValues.formName}</h1>
    //                 <div className="decurationHR"></div>

    //             </div>

    //             <div className="Logo">
    //                 <img  className="Logo--img" src={logo} alt="logo" width={60} height={60} />

    //             </div>

    //         </div>

    //         <InputArea/>

    //     </div>
    // </div>
  );
}
