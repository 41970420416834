import React, { useEffect } from "react";
import "./App.css";
import ClientQueryForm from "./Pages/ClientQueryForm/ClientQueryForm";

function App() {
  // useEffect(() => {
  //   fetch("https://pythongsheetapi.onrender.com");
  // });

  return (
    <div>
      <ClientQueryForm />
    </div>
  );
}

export default App;
