import React from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


import "./ClientForm.css";
// import { appendSpreadsheet } from "./../../api/sheet_api";





class ResourceModel {
  constructor(count, profession, experience) {
    this.count = count;
    this.profession = profession;
    this.experience = experience;
  }
}

class RowModel {
  constructor(
    // created_on,
    client_name,
    company_name,
    contact_no,
    email,
    work_detail,
    employee_detail
  ) {
    // this.created_on = created_on;
    this.client_name = client_name;
    this.company_name = company_name;
    this.contact_no = contact_no;
    this.email = email;
    this.work_detail = work_detail;
    this.employee_detail = employee_detail;
  }
}

class ClientForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      companyName: "",
      contact: "",
      email: "",
      kindOfWork: "",
      resources: [new ResourceModel()],
      formErrors: {
        name: "",
        companyName: "",
        contact: "",
        email: "",
        kindOfWork: "",
      },
      formValid: false,
    };
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  };

  handleSubmit() {
    let fieldValidationErrors = this.state.formErrors;
    Object.keys(fieldValidationErrors).forEach(
      (k) => (fieldValidationErrors[k] = "")
    );

    let errors = false;

    if (!this.state.name) {
      errors = true;
      fieldValidationErrors.name = "Please enter name";
    }
    if (!this.state.companyName) {
      errors = true;
      fieldValidationErrors.companyName = "Please enter company name";
    }
    if (!this.state.contact) {
      errors = true;
      fieldValidationErrors.contact = "Please enter contact number";
    }
    if (!this.state.email) {
      errors = true;
      fieldValidationErrors.email = "Please enter email";
    } else if (!this.validateEmail(this.state.email)) {
      errors = true;
      fieldValidationErrors.email = "Please enter a valid email";
    }
    if (!this.state.kindOfWork) {
      errors = true;
      fieldValidationErrors.kindOfWork =
        "Please enter some details about your requirement";
    }

    this.setState({
      formErrors: fieldValidationErrors,
    });

    if (errors) {
      return;
    }

    console.log(this.state);



    
      console.log("state", this.state.resources)
      let sendDataList = [];
      for (let i in this.state.resources) {
        let strData = `${this.state.resources[i]["count"]}- ${this.state.resources[i]["profession"]} - ${this.state.resources[i]["experience"]} xp`;
        sendDataList.push(strData);
        console.log(sendDataList);
      
    }
    sendDataList =  sendDataList.join(" , ")


    const row = new RowModel(
      // new Date(),
      this.state.name,
      this.state.companyName,
      this.state.contact,
      this.state.email,
      this.state.kindOfWork,
      sendDataList
     
      
    );
    // event.preventDefault();
    console.log(row);

    // appendSpreadsheet(row);

    async function postData(url = '', data = {}) {
     
      const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
          
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer', 
        body: JSON.stringify(data) 
      });
      return response.json(); 
    }
    
    postData('https://pythongsheetapi.onrender.com/post', row)
      .then((data) => {
        console.log(data); 
      });

    




  }

  addMore() {
    this.setState({
      resources: [...this.state.resources, new ResourceModel()],
    });
  }




  remove(index) {
    // let items = [...this.state.resources];
    let items = this.state.resources.filter((e, idx) => idx !== index);
    this.setState({
      resources: items,
    });
  }

  setResourseCount(index, value) {
    let items = [...this.state.resources];
    let item = { ...items[index] };
    item.count = value;
    items[index] = item;
    this.setState({ resources: items });
  }
  setResourseExperience(index, value) {
    let items = [...this.state.resources];
    let item = { ...items[index] };
    item.experience = value;
    items[index] = item;
    this.setState({ resources: items });
  }
  setResourseProfession(index, value) {
    let items = [...this.state.resources];
    let item = { ...items[index] };
    item.profession = value;
    items[index] = item;
    this.setState({ resources: items });
  }

  render() {
    return (
      <form noValidate>
        <Container>
          <Row>
            <Col lg={6} md={6}>
              {/* <div className="Name--div"> */}
              <Container>
                <Row>
                  <Col>
                    <label className="lable-name">Full Name* : </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      className="input"
                      type="text"
                      value={this.state.name}
                      required
                      onChange={(e) =>
                        this.setState({
                          name: e.target.value,
                        })
                      }
                      //   value={name}
                      //   onChange={(e) => setName(e.target.value)}
                      //   onClick={() => setNameWarrning("")}
                    />
                    {/* <p className="warning">{nameWarrning}</p> */}
                    {/* </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.state.formErrors.name ? (
                      <p className="warning">{this.state.formErrors.name}</p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col lg={6} md={6}>
              <Container>
                <Row>
                  <Col>
                    <label className="lable-name">Company Name* : </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      className="input"
                      type="text"
                      required
                      value={this.state.companyName}
                      //   value={companyName}
                      onChange={(e) =>
                        this.setState({
                          companyName: e.target.value,
                        })
                      }
                      //   onClick={() => setCompanyNameWarning("")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.state.formErrors.companyName ? (
                      <p className="warning">
                        {this.state.formErrors.companyName}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6}>
              {/* <div className="Name--div"> */}
              <Container>
                <Row>
                  <Col>
                    <label className="lable-name">Contact Number* : </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      className="input"
                      type="number"
                      maxLength={12}
                      required
                      value={this.state.contact}
                      onChange={(e) =>
                        this.setState({ contact: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.state.formErrors.contact ? (
                      <p className="warning">{this.state.formErrors.contact}</p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col lg={6} md={6}>
              <Container>
                <Row>
                  <Col>
                    <label className="lable-name">Email Address* : </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      className="input"
                      type="text"
                      required
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.state.formErrors.email ? (
                      <p className="warning">{this.state.formErrors.email}</p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12}>
              {/* <div className="Name--div"> */}
              <Container>
                <Row>
                  <Col>
                    <label className="lable-name">
                      Which Type Of Work You Want To Outsource With Us ? * :{" "}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <textarea
                      className="textarea-input"
                      type="text"
                      required
                      value={this.state.kindOfWork}
                      onChange={(e) =>
                        this.setState({ kindOfWork: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.state.formErrors.kindOfWork ? (
                      <p className="warning">
                        {this.state.formErrors.kindOfWork}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12}>
              {/* <div className="Name--div"> */}
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <label className="lable-name">Resource Details</label>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <div className="logo-container">
                      <Button variant="light" onClick={() => this.addMore()}>
                        Add More
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} md={3} sm={9} className="d-none d-md-block">
                    <span>No. of Employees * :</span>
                  </Col>

                  <Col lg={3} md={3} sm={9} className="d-none d-md-block">
                    <span>Field of Resource * :</span>
                  </Col>

                  <Col lg={3} md={3} sm={9} className="d-none d-md-block">
                    <span>Experience * :</span>
                  </Col>
                </Row>
                {this.state.resources.map((element, index) => {
                  return (
                    <Row key={index}>
                      {/* <Col lg={4} md={4} sm={12}>
                            No. Of Employee* :
                            </Col> */}
                      <Col lg={3} md={3} sm={12}>
                        <input
                        name="noOf"
                          type="number"
                          className="input"
                          value={element.count}
                          placeholder="No. of employees"
                          onChange={(e) =>
                            this.setResourseCount(index, e.target.value)
                          }
                        />
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <input
                          name="profession"
                          type="text"
                          className="input"
                          value={element.profession}
                          placeholder="Field"
                          onChange={(e) =>
                            this.setResourseProfession(index, e.target.value)
                          }
                        />
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <input
                          name="xp"
                          type="text"
                          className="input"
                          placeholder="Experience of resource"
                          value={element.experience}
                          onChange={(e) =>
                            this.setResourseExperience(index, e.target.value)
                          }
                        />
                      </Col>
                      <Col lg={3} md={3} sm={3} style={{ textAlign: "right" }}>
                        {this.state.resources.length > 1 ? (
                          <Button
                            variant="danger"
                            onClick={() => this.remove(index)}
                          >
                            Remove
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </Col>
          </Row>
          <Row>
            <Col className="h-center">
              <Button
                variant="outline-primary"
                className="theme-button"
                size="lg"
                onClick={() => this.handleSubmit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

export default ClientForm;
